$primary: #783DBE;
$danger: #FE2B38;
$light: #FFFFFF;
$success: #00C767;
$dark: #000000;
$warning: #F37501;
$info: #3390FF;

$primary100: #F0D3FF;
$primary300: #AB6BF1;
$primary400: #783DBE;
$primary700: #450B8D;

$warning300: #F3BE01;

$borderCampos: #F0D3FF;
$colorTexto: #212529;
$colorTextoPequenio: #747576;
$textoCampo: #323232;
$colorLabelCheck:#101010;
$colorPlaceholder: #A5A5A5;
$colorTextosBarra: #7C7C7C;
$colorCeldasTablas: #F7F7F8;

$Surface300: #A5A5A5;
$Surface400: #7C7C7C;
$Surface500: #323232;



$colorTextosFooter: #FFFFFF;
$colorBtnHover: #F0D3FF;

$backgroundInfo: rgba(189, 162, 224, 0.2);
$backgroundContenido: rgba(247, 247, 248, 1);

$linea: #E7E7E7