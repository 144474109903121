.circle-step {
    background-color: $light;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    &.active {
        background-color: $primary300;
        color: $light;
    }
    &.complete {
        background-color: $success;
        color: $light;
    }
}