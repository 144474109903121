.radio-box {
    position: relative;
    padding: 0;
    margin: 0;
    height: 100%;

    input[type=radio] {
        display: none;
        + .form-check-label {
            border: 2px solid $light;
            background-color: $light;
            border-radius: 24px;
            padding: 40px 32px;
            width: 100%;
            height: 100%;
            
            .texto-informacion {
                font-size: 14px;
                line-height: 20px;
            }
            .numero {
                color: $primary;
                font-family: 'Poppins', Arial, Helvetica, sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    input[type=radio]:checked + .form-check-label  {
        border: 2px solid $primary300;
    }

}