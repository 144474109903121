body {
    background: #F7F7F8;
}
.fondo-semi-circulo {
    background: #F0D3FF;
    border-radius: 340px 0 0 340px;
    filter: blur(100px);
    position: absolute;
    top: 50%;
    right: 0;
    width: 170px;
    height: 340px;
}
.cerrar-sesion {
    font-family: 'Lato', Arial, Helvetica, sans-serif;;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    position: absolute;
    bottom: 16px;
    &:hover {
        color: $primary;
        text-decoration: underline;
    }
}
.linea-separacion {
    color: $linea;
    margin: 8px 0;
    opacity: 1;
}

.logo-atria-color {
    width: 116px;
    height: 32px;
}

.link-inicio {
    .nav-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: 'Lato', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }
    .nav-link-espacio {
        @extend .nav-link;
        cursor: default;
    }
}

.select-sucursal {
    width: 100%;
    .form-select {
        border-radius: 8px;
        color: $Surface500;
        font-family: 'Lato', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
    }
    .label-sucursal {
        color: $Surface500;
        display: flex;
        align-items: center;
        font-family: 'Lato', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        opacity: 1 !important;
    }
}


.seccion-home {
    padding-top: 32px;

    .col-menu-lateral {
        background-color: $backgroundInfo;
        border-radius: 24px;
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        min-height: 656px;
        max-height: 656px;
        padding: 48px 40px 16px 40px;
        position: relative;

        .logo-atria-color {
            width: 154px;
            height: 42px;
            margin-bottom: 53px;
        }
        .texto-titulo {
            font-size: 48px;
            line-height: 48px;
            margin-bottom: 32px;
        }
        .nav-link {
            cursor: pointer;
            color: $Surface500;
            &:hover {
                text-decoration: underline;
            }
        }
        .opcion-menu {
            list-style: none;
            padding-left: 0;
            margin: 0;
            position: relative;
            z-index: 1;
            li {
                display: flex;
                align-items: center;
                padding-bottom: 32px;
                i {
                    margin-right: 8px;
                    flex-shrink: 0;
                }
                .circle {
                    background: $success;
                    border-radius: 50%;
                    display: none;
                    margin-left: 4px;
                    width: 8px;
                    height: 8px;
                }
                &.list-active {
                    .circle {
                        display:inline-block;
                    }
                    .icon-trophy {
                        background: url(../../assets/images/icons/trophy-active.png) 0 0 no-repeat;
                    }
                    .icon-seguros {
                        background: url(../../assets/images/icons/security_active.svg) -3px 0 no-repeat;
                    }
                    .icon-solicitud {
                        background: url(../../assets/images/icons/solicitudes-active.png) -3px 0 no-repeat;
                    }
                }
            }
        }
    }
    .col-contenido {
        padding-left: 40px;
        position: relative;
        .texto-subtitulo {
            color: $Surface500;
            font-family: 'Poppins', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            margin: 0;
        }
        .texto-informacion {
            color: $Surface500;
            margin: 0;
        }
    }
}

.seccion-solicitud-credito,
.seccion-solicitud-seguro {
    padding-top: 32px;
    
    .col-menu-lateral {
        background-color: $backgroundInfo;
        border-radius: 24px;
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        min-height: 656px;
        padding: 24px 40px 16px 40px;
        position: relative;
        
        .texto-titulo {
            font-size: 48px;
            line-height: 48px;
            margin-bottom: 32px;
            margin-top: 36px;
        }

        .opcion-menu {
            list-style: none;
            padding-left: 0;
            margin: 0;
            li {
                display: flex;
                align-items: center;
                padding-bottom: 32px;
                h3 {
                    color: $Surface500;
                    margin-bottom: 0;
                    margin-left: 16px;
                }
            }
        }
    }
    
    .col-formulario {
        /*padding-top: 73px;*/
        padding-left: 40px;
        
        .texto-subtitulo {
            color: $Surface500;
            font-family: 'Poppins', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            margin: 0;
        }
        .texto-informacion {
            color: $Surface500;
            margin: 0;
        }

        .barra-progreso {
            margin: 16px 0 32px 0;
        }
        .barra-progreso-montos {
            margin: 8px 0;
        }

        .col-formularios-soliciud {
            padding-top: 25px;
            width: 560px;
            min-height: 572px;
        }
    }
    
}

.seccion-cotizador {
    @extend .seccion-solicitud-credito;
}

.seccion-detalle-solicitud {
    @extend .seccion-home;
    
    .texto-titulo {
        margin: 48px 0 0 0;
        margin-bottom: 84px !important;
    }
    .titulo-folio {
        font-size: 18px !important;
    }
    .opcion-menu {
        li {
            padding: 16px 0;
            i {
                margin-right: 16px;
                width: 32px;
            }
            &.list-active {
                i.icon-historial {
                    background: url(../../assets/images/icons/historial-active.png) 0 0 no-repeat;
                }
            }
            &.list-active {
                i.icon-documentacion {
                    background: url(../../assets/images/icons/documentacion-active.png) 0 0 no-repeat;
                }
            }
            &.list-active {
                i.icon-zoom-area {
                    background: url(../../assets/images/icons/zoom-area-active.png) 0 0 no-repeat;
                }
            }
        }
    }
    .card-datos-solicitud {
        background-color: transparent;
        font-family: 'Lato', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        width: 612px;
        .card-title {
            font-family: 'Poppins', Arial, Helvetica, sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
        }
        .card-subtitle {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
        }
        .tipo-cargo {
            font-weight: 700;
        }
    }

    .card-listado-proceso {
        border-radius: 24px;
        width: 612px;
        .card-body {
            padding: 24px 16px;
            .lista-procesos {
                padding: 0;
                .item-proceso {
                    display: flex;
                    align-items: center;
                    /*justify-content: space-between;*/
                    font-family: 'Lato', Arial, Helvetica, sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    padding: 16px 0;
                    &:before {
                        content: "";
                        background: url("../../assets/images/icons/checkmark.png") 0 0 no-repeat;
                        width: 24px;
                        height: 24px;
                        margin-right: 16px;
                    }
                    &.condicionado:before{
                        background: url("../../assets/images/icons/warning.png") 0 0 no-repeat;
                    }
                    &.rechazada::before {
                        background: url("../../assets/images/icons/error.png") 0 0 no-repeat;
                    }
                    .descripcion {
                        width: 50%;
                    }
                    .fecha {
                        width: 50%;
                        text-align: right;
                    }
    
                }
                
            }
            .lista-procesos-seguros {
                .item-proceso {
                    border-bottom: 1px solid #F7F7F8;
                    border-radius: 8px;
                    box-shadow: 0px 2px 9px rgba(165, 165, 165, 0.46), 0px 11px 20px rgba(165, 165, 165, 0.2);
                    margin-bottom: 16px;
                    padding: 16px 24px 16px 24px;
                    justify-content: space-between;

                    &:before {
                        content: "";
                        background: url("../../assets/images/icons/document-morado.svg") -5px 0 no-repeat;
                        width: 23px;
                        height: 32px;
                        margin-right: 16px;
                        flex-shrink: 0;
                        opacity: 0.5;
                    }
                    
                    .descripcion {
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 20px;
                        opacity: 0.5;
                        padding: 0 8px;
                    }
                    .fecha {
                        color: $primary300;
                        font-size: 15px;
                        padding: 0 8px;
                        opacity: 0.5;
                        width: 100px;
                    }
                    .snippet-status {
                        opacity: 0.5;
                        min-height: 52px;
                        text-align: center;
                    }
                    &:nth-child(1) {
                        .descripcion {
                            opacity: 1;
                        }
                        .fecha {
                            opacity: 1;
                        }
                        .snippet-status {
                            opacity: 1;
                        }
                        &:before { 
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

/*Iconos */
.icon-regresar {
    width: 32px;
    height: 32px;
}

.col-contenido {
    background-color: $backgroundContenido;

    .circle-progress {
        margin-right: 32px;
    }
}

.col-plazos {
    background-color: $light;
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    padding: 16px 21px;
    border-radius: 24px;
    .monto {
        font-weight: 600;
        margin-top: 8px;
    }
    .plazo {
        color: $primary300;
        font-weight: 400;
        font-size: 14px;
    }
}

.card-formulario {
    background: transparent;
    border: 0;
    /*width: 464px;*/

    .card-body {
        padding-top: 0;
        .col-check-colonia {
            padding-left: 28px;
            margin-bottom: 16px;
        }
        .texto-subtitulo {
            color: $dark;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 16px;
        }
        .frm-verificacion-codigo {
            .texto-subtitulo {
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 0;
                &.texto-verifica-codigo {
                    font-size: 22px;
                    line-height: 26px;
                }
            }
            .texto-informacion {
                margin-bottom: 16px;
            }
        }
    }
    .card-title {
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
    }
    .card-text {
        font-family: 'Lato', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
        &.texto-informacion-roboto {
            color: $textoCampo;
            font-family: 'Roboto', Arial, Helvetica, sans-serif;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 32px;
        }
    }

    .formulario-datos-pago {
        .texto-informacion-pequenio {
            color: $colorTextoPequenio;
            text-align: center;
            margin-bottom: 32px;
        }
        .texto-foot-message {
            color: $colorTextoPequenio;
        }
    }

    &.card-domicilio {
        .existe-colonia{
            padding-left: 28px;
            margin: 4px 0 20px 0;
        }
    }
}

.card-plan-cotizacion {
    background-color: transparent;
    border: 0;
    
    .card-body {
        background-color: $light;
        border-radius: 24px;
        padding: 28px 32px 44px 32px;
        font-family: 'Lato', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        .texto-info-calculo {
            font-family: 'Lato', Arial, Helvetica, sans-serif;
            font-weight: 700;
        }
        .tipo-pago {
            font-size: 12px;
            margin: 0;
        }
        .monto {
            font-family: 'Poppins', Arial, Helvetica, sans-serif;
            font-weight: 500;
            font-size: 32px;
            line-height: 28px;
            margin: 8px 0 8px 0;
        }
        .mensualidades {
            color: $primary300;
            line-height: 20px;
        }
        .texto-label {
            color: $textoCampo;
            margin: 0;
        }
        .texto-resultado {
            color: $textoCampo;
            font-weight: 700;
            font-size: 16px;
            margin: 0;
        }
    }
    .card-footer {
        background-color: transparent;
        padding: 40px 0 0 0;
    }
}

.card-informacion-plan {
    border-radius: 24px;
    color: $Surface500;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 32px;
    
    .card-title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px;
    }
    .tipo-pago {
        font-family: 'Lato', Arial, Helvetica, sans-serif;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 8px;
    }
    .monto {
        font-weight: 500;
        font-size: 32px;
        line-height: 28px;
        margin-bottom: 8px;
    }
    .meses {
        color: $primary300;
        font-size: 14px;
        line-height: 20px;
    }
}

.col-reenvio {
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin: 16px 0;

    .ligas {
        display: flex;
        justify-content: center;
        color: $primary400;
        .timer {
            font-weight: 700;
            margin-bottom: 0;
            margin: 0 32px;
        }
        .nav-link{
            font-weight: 900;
            font-size: 10px;
            line-height: 18px;
            &:hover {
                text-decoration: underline;
            }
            &.disabled {
                color: $Surface400;
            }
        }
    }
}

.card-clausulas {
    border-radius: 24px;
    color: $Surface500;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-style: normal;
    line-height: 20px;
    .card-body {
        padding: 32px 32px 24px 32px;
        
        .card-subtitle {
            font-weight: 700;
            font-size: 14px;
            margin-bottom: 24px;
        }
    }
    
}

.form-referencias {
    .card-title {
        color: $Surface500;
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
    }
}

.header-resultado {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .texto-subtitulo {
        width: 52%;
    }
}

.seccion-resumen-solicitud {
    font-style: normal;
    font-weight: 400;
    .linea-separacion {
        color: $Surface400;
        margin: 32px 0;
    }
    .card-title {
        color: $Surface500;
        display: flex;
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 32px;
    }
    .texto-label {
        color: $Surface500;
        font-family: 'Lato', Arial, Helvetica, sans-serif;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
    }
    .texto-resultado {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
    }
    .nav-link {
        color: $primary400;
        font-family: 'Lato', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
    }
}
.col-aviso-privacidad {
    .nav-link {
        color: $Surface500;
        font-weight: 700;
        display: inline-block;
        text-decoration-line: underline;
    }
    b {
        color: $Surface500;
        font-weight: 700;
    }
}
.seccion-detalle-plan {
    max-width: 560px;
    .nav-link {
        color: $primary400;
        font-family: 'Lato', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
    }
    .card-plan-cotizacion {
        margin-top: 48px;
        .card-body {
            padding: 32px;
        }
    }
}
.seccion-documentacion {
    max-width: 560px;
    .card-formulario {
        .card-body {
            .card-title {
                color: $Surface500;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
            }
            .label-input-file {
                color: $Surface500;
                font-weight: 700;
                font-size: 12px;
                line-height: 20px;
                margin-bottom: 8px;
            }
            .linea-separacion {
                margin: 32px 0;
            }
        }
    }
}

.caso-alterno {
    color: $info;
}
.condicionado {
    color: $warning300;
}
.en-proceso {
    color: $warning;
}
.rechazada {
    color: $danger;
}
.aprobada-success {
    color: $success;
}

.card-gris {
    background: rgba(179, 179, 179, 0.15);
    backdrop-filter: blur(27.1828px);
    border-radius: 24px;
    color: $Surface500;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    .card-body {
        padding: 24px;

        .card-title {
            font-weight: 700;
            font-size: 16px;
        }
        .titulo-tabla {
            span {
                color: $primary300;
                font-weight: 900;
                font-size: 10px;
                line-height: 18px;
            }   
        }
        .card-text-mini {
            color: $Surface400;
            display: block;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
        }
        .card-text {
            color: $Surface500;
            font-size: 14px;
            line-height: 20px;
        }
        .texto-liga {
            text-decoration: underline;
            display: inline-block;
            &:hover {
                color: $primary400;
            }
        }

        .circle {
            background: $primary400;
            border-radius: 50%;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Poppins', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            position: absolute;
            width: 40px;
            height: 40px;
            z-index: 1;
            left: 45.5%;
            top: 20%;
        }

        .card-perfiles {
            border-radius: 12px;
            height: 100%;
            .card-body {
                padding: 24px 16px;
                text-align: center;
                .foto-perfil {
                    border-radius: 50%;
                    width: 56px;
                    height: 56px;
                    margin-bottom: 16px;
                }
                .pronombre {
                    color: $Surface400;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 20px;
                    margin: 0;
                }
                .nombre {
                    color: $Surface500;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                }
                .cantidad {
                    color: $primary300;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    margin: 0;
                }
            }
        }
    }
}

/* Tooltip */
.tooltip-inner {
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    padding: 8px 8px;
}
.tooltip-inner .titulo-tooltip {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
}
.tooltip-inner i {
    margin-right: 6px;
    background-size: contain;
    width: 12px;
    height: 12px;
    vertical-align: bottom;
}
.card-semi-round {
    z-index: 1;
}

.card-border-morado {
    border: 1px solid $primary300;
    border-radius: 16px;
    width: 320px;
    padding: 0;
    .card-body {
        padding: 32px;
        
        .card-title {
            color: $Surface500;
            font-family: 'Poppins', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 46px;
            margin-bottom: 24px;
        }
        .card-subtitle {
            color: $primary300;
            font-family: 'Lato', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
        }
        .pill {
            background: $primary300;
            border-radius: 300px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Lato', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 900;
            font-size: 10px;
            line-height: 18px;
            text-align: center;
            width: 133px;
            height: 22px;
    
            .icon-trophy-white {
                margin-left: 10px;
            }
        }
        .logo-seguro {
            margin: 8px 0;
        }
    }
}


/* Seccion Seguros */
.card-resumen-seguro {
    border-radius: 24px;
    height: 100%;
    .card-body {
        padding: 24px 24px 24px 24px;
        .card-title {
            color: $primary300;
            font-family: 'Poppins', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
        .texto-label {
            color: $textoCampo;
            font-family: 'Lato', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 8px;
        }
        .texto-resultado {
            color: $textoCampo;
            font-family: 'Lato', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
        }
        .tabla-detalles tbody tr:nth-child(odd) {
            background-color: $colorCeldasTablas;
        }
    }
}

.card-vendedor {
    @extend .card-resumen-seguro;
    .card-body{
        .card-title {
            color: $Surface500;
        }
    }
    
}

.slick-slider,
.slick-list {
    width: 700px;
}
.slick-dots li button:before {
    color: #D9D9D9 !important;
    font-size: 10px !important;
    opacity: 1 !important;
}
.slick-dots li.slick-active button:before {
    color: $primary300 !important;
}
.slick-dots {
    bottom: -35px !important;
}

.snippet-status {
    background: #AB6BF1;
    border-radius: 20px;
    color: #FFFFFF;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 16px 6px 16px;
    min-width: 100px;
}

.formulario-documentos {
    .texto-liga {
        display: inline-block;
        text-decoration: underline;
        &:hover {
            color: #783DBE;
        }
    }
}