.barra-progreso {
    margin: 8px 0;
    .progress {
        height: 12px;
    }
    .label-bar {
        display: flex;
        justify-content: space-between;
        padding-left: 16px;
        padding-right: 16px;
        span {
            color: $colorTextosBarra;
            font-family: 'Lato', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
        }
    }
}

.barra-progreso-montos {
    @extend .barra-progreso;
    .progress {
        height: 4px;
    }
}

.barra-etapas {
    background-color: #FFFFFF;
    border-radius: 0;
    height: 24px;
    .progress-bar {
        background-color: $primary300;
    }
}