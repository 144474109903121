/*.modal-backdrop.show {
    opacity: 0.9;
}*/
.modal-dialog {
    .modal-content {
        background-color: transparent;
        border: 0;
        text-align: center;
        .spinner-border {
            border-width: 15px;
            width: 150px;
            height: 150px;
            margin: 0 auto;
        }
        p {
            color: $light;
            font-family: 'Lato', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            margin: 16px 0;
        }
    }
}