.table-solicitudes {
    thead {
        tr {
            th {
                color: $Surface500;
                font-family: 'Lato', Arial, Helvetica, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                padding: 0 12px 16px 24px;
                border-color: $primary300;
                &:last-child{
                    text-align: right;
                }
            }
        }
    }
    tbody {
        tr{
            cursor: pointer;
            &:nth-child(even) {
                background-color: $colorCeldasTablas;
            }
            td {
                border-color: $colorCeldasTablas;
                color: $Surface500;
                font-family: 'Lato', Arial, Helvetica, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                padding: 16px 12px 16px 24px;
                &:nth-child(2) {
                    font-weight: 400;
                }
                &:nth-child(4) {
                    font-weight: 400;
                }
                &:last-child{
                    text-align: right;
                }
                .circle {
                    background: $primary300;
                    border-radius: 50%;
                    display: inline-block;
                    margin-left: 4px;
                    width: 10px;
                    height: 10px;
                }
            }
            &.aprobada-success {
                //background-color: $colorCeldasTablas;
                td {
                    &:nth-child(3) {
                        color: $success;
                    }
                    .circle {
                        display: none;
                    }
                }
            }
            &.rechazada {
                td {
                    &:nth-child(3) {
                        color: $danger;
                    }
                }
            }
            &.en-proceso {
                td {
                    &:nth-child(3) {
                        color: $warning;
                    }
                }
            }
            &.condicionado {
                td {
                    &:nth-child(3) {
                        color: $warning300;
                    }
                }
            }
            &.caso-alterno {
                td {
                    &:nth-child(3) {
                        color: $info;
                    }
                }
            }
            
        }
    }
}

.table-etapas {
    color: $Surface500;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;

    thead {
        tr {
            th {
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                border: 0;
                /*padding: 8px 12px;*/
                padding: 8px 12px 24px 12px;
            }
            th:nth-child(1) {
                width: 220px;
            }
            th:nth-child(2) {
                width: 95px;
            }
            th:nth-child(3) {
                width: 95px;
            }
            th:nth-child(4) {
                width: 95px;
            }
            th:nth-child(5) {
                width: 95px;
            }
            th:nth-child(6) {
                text-align: center;
            }
        }
    }
    tbody {
        tr {
            td {
                padding: 8px 12px;
                border: 0;
            }
            td:nth-child(3) {
                text-align: center;
            }
        }
    }

    
    .numero {
        display: flex;
        align-items: center;
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        margin-right: 12px;
        vertical-align: middle;
    }
    .nombre {
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
    }
    
    .texto-comision {
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
    }
    .proceso {
        .texto-comision {
            color: $success;
        }
    }
    
    .rechazado {
        .texto-comision {
            color: $Surface300;
        }
    }
}

.tabla-seguros {
    border-collapse: separate;
    border-spacing: 0 16px;
    thead {
        tr {
            th {
                color: $Surface400;
                font-family: 'Lato', Arial, Helvetica, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                padding: 0 12px 16px 24px;
                
                &:last-child{
                    text-align: right;
                }
            }
        }
    }
    tbody {
        tr{
            background: #FFFFFF;
            border-bottom: 1px solid $colorCeldasTablas;
            box-shadow: 0px 2px 9px rgba(165, 165, 165, 0.46), 0px 11px 20px rgba(165, 165, 165, 0.2);
            border-radius: 8px;
            cursor: pointer;
            td {
                
                border-color: $colorCeldasTablas;
                color: $Surface500;
                font-family: 'Lato', Arial, Helvetica, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                padding: 16px 12px 16px 24px;
                vertical-align: middle;
                
                .circle {
                    background: $primary300;
                    border-radius: 50%;
                    display: inline-block;
                    margin-left: 4px;
                    width: 10px;
                    height: 10px;
                }
                &:nth-child(1) {
                    font-weight: 700;
                }
                &:last-child{
                    text-align: right;
                }
                .pill {
                    background: $primary300;
                    border-radius: 20px;
                    color: #FFFFFF;
                    display: flex;
                    font-family: 'Lato', Arial, Helvetica, sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    justify-content: center;
                    align-items: center;
                    padding: 6px 16px;
                    min-height: 52px;
                    text-align: center;
                }
                .detalle-estatus {
                    color: $primary300;
                    font-family: 'Lato', Arial, Helvetica, sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 13px;
                    margin: 8px 0 0 0;
                }
            }
            &.aprobada {
                td {
                    .pill {
                        background: $success;
                    }
                    .detalle-estatus {
                        color: $success;
                    }
                }
            }
            &.rechazada {
                td {
                    .pill {
                        background: $danger;
                    }
                    .detalle-estatus {
                        color: $danger;
                    }
                }
            }
            .liga-poliza {
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
}