$theme-colors: (
  "primary": $primary,
  "danger": $danger,
  "light": $light,
  "success": $success,
  "dark": $dark,
  "warning": $warning,
  "info": $info
  /*"secondary":  $secondary,
  
  "warning":    $warning,*/
);

/* Elementos */

/* Form Control */
$input-color: $textoCampo;
$input-border-width: 1px;
$input-border-color:  #FFFFFF;
$input-font-family: 'Lato', Arial, Helvetica, sans-serif;
$input-font-size: 14px;
$input-font-weight: 400;
$input-border-radius: 0;
$input-padding-y: 12px;
$input-padding-x: 16px;
$input-focus-box-shadow: none;

$form-label-font-size: 12px;
$form-label-font-weight: 700;
$form-label-color: $colorTexto;

$input-placeholder-color: $colorPlaceholder;

/*Checkbox*/
$form-check-input-bg: transparent;
$form-check-input-width: 1.5em;
$form-check-label-color: $colorLabelCheck;
$form-check-input-focus-box-shadow: none;
$form-check-input-border: 2px solid rgba($primary, 1);

/*Select*/
$form-select-focus-box-shadow: none;
$form-select-indicator-color: $textoCampo;
$form-select-font-size: 14px;
$form-feedback-icon-invalid: none;

/*Botones*/
$btn-padding-y: 16px;
$btn-padding-x: 24px;
$btn-font-family: 'Lato', Arial, Helvetica, sans-serif;
$btn-font-size: 16px;
$btn-line-height: 20px;
$btn-font-weight: 700;
$btn-border-radius: 12px;
$btn-focus-box-shadow: none;
$btn-disabled-opacity: 1;

/* Bars Progress */
$progress-height: 4px;
$progress-bg: $colorBtnHover;
$accordion-border-radius: 0;

/* Accordion */
$accordion-button-active-bg: #FFFFFF;
$accordion-button-focus-box-shadow: none;
$accordion-border-width: 0;

/* Cards */
$card-border-width: 0;
$card-spacer-y: 24px;
$card-spacer-x: 24px;

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
/*@import "~bootstrap/scss/mixins";*/

input.form-control:focus {
  border: 1px solid $borderCampos;
}
.form-label {
  font-family: "Lato", Arial, Helvetica, sans-serif;
  line-height: 20px;
}
.form-check-label {
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

//Botones 

/* Boton mixin */
@mixin button-variant(
  $background,
  $color,
) {
  --#{$prefix}btn-bg: #{$background};
  --#{$prefix}btn-color: #{$color};
}
.btn-light {
  @include button-variant($light, $primary);
}
.btn-primary-300 {
  @include button-variant($primary300, $light);
}

.btn:focus {
  box-shadow: none;
}
.btn-primary:hover {
  background-color: $colorBtnHover;
  border-color: $colorBtnHover;
  color: $primary;
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  background-color: #A5A5A5;
  border: 1px solid #A5A5A5;
}

//Validacion de formularios
.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: none;
}

// CheckBox 
.form-check-input-primary-100{
  .form-check-input {
    background-color: transparent;
    border: 1px solid $primary100;
  }
}

// Alertas
@mixin alert-variant($background, $border, $color) {
  --#{$prefix}alert-color: #{$color};
  --#{$prefix}alert-bg: #{$background};
  --#{$prefix}alert-border-color: #{$border};

  @if $enable-gradients {
    background-image: var(--#{$prefix}gradient);
  }

  .alert-link {
    color: shade-color($color, 20%);
  }
}

.alert-info-primary {
  @include alert-variant($primary300, 0, $light);
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  .btn-close {
    background: url(../../assets/images/icons/icon-cerrar.svg) center/1em auto no-repeat;
    width: 16px;
    height: 16px;
    opacity: 1;
    padding: 20px 40px;
    &:focus {
      box-shadow: none;
    }
  }
}