.menu-chip {
    margin: 16px 0 24px 0;
    .nav-item {
        margin-right: 8px;
        .nav-link {
            background: $light;
            border-radius: 20px;
            color: $Surface500;
            font-family: 'Lato', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            padding: 6px 16px;
            height: 32px;
            &.active {
                background: $Surface500;
                color: $light;
            }
        }
    }


}