.col-input {
    position: relative;

    .icon-eye {
        background: url("../../assets/images/icons/eye.svg") 0 0 no-repeat;
        cursor: pointer;
        display: inline-block;
        background-size: 24px 24px;
        position: absolute;
        bottom: 12px;
        right: 29px;
        width: 24px;
        height: 24px;
    }
    .icon-eye-off {
        cursor: pointer;
        position: absolute;
        bottom: 12px;
        right: 29px;
        width: 24px;
        height: 24px;
    }
    
    .is-invalid {
        + .icon-eye {
            bottom: 12px;
        }
    }
}