.modal-error {
    .modal-dialog {
        .modal-content {
            background: rgba(0, 0, 0, 0.6);
            backdrop-filter: blur(54.3656px);
            border-radius: 24px;
            .modal-header {
                border-bottom: 0;
                margin: 40px 40px 32px 0;
                .btn-close {
                    color: $light;
                    background: url(../../assets/images/icons/icon-cerrar.svg) 0 0 no-repeat;
                    border-radius: 0;
                    opacity: 1;
                    width: 16px;
                    height: 16px;
                    padding: 0;
                }
            }
            .modal-body {
                padding: 0 56px 64px 56px;
                .texto-error{
                    color: $light;
                    font-size: 36px;
                    line-height: 36px;
                    margin-bottom: 16px;
                }
                .texto-mensaje-2 {
                    font-size: 14px;
                    line-height: 16px;
                    margin: 8px 0;
                }
            }
        }
    }
}

.modal-alerta {
    @extend .modal-error;
    .modal-body {
        color: $light;

        .texto-subtitulo {
            font-family: 'Poppins', Arial, Helvetica, sans-serif;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 32px;
        }
        p.texto-informacion {
            font-family: 'Lato', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-size: 14px ;
            line-height: 20px;
        }
    }

    .modal-documentos-mano {
        text-align: left;
        .modal-body .texto-subtitulo {
            margin-bottom: 16px;
        }
        .texto-descripcion {
            font-family: 'Lato', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin: 0 0 24px 0;
        }
        .lista-documentos {
            padding: 0;
        }
        .form-check {
            .form-check-label {
                color: $light;
            }
        }
        .descarga-pdf {
            color: $primary300;
            font-family: 'Lato', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 900;
            font-size: 10px;
            line-height: 18px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
        }
    }
}

