
.datos-progress-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    //height: 100%;
    height: 90%;
    margin: 0 auto;
    z-index: -1;

    .label {
        color: $Surface500;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        margin: 0;
    }
    .texto-ganancia {
        color: $primary300;
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
        margin: 0;
    }
    .texto-ganar {
        @extend .texto-ganancia;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }
}

.RCP {
    position: relative;
    cursor: pointer;
}
  
.tooltip-one {
    background: $Surface500;
    border-radius: 5px;
    color: #FFFFFF;
    display: none;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    margin-right: -110px;
    text-align: center;
    padding: 4px;
    position: absolute;
    filter: drop-shadow(0 3px 5px #ccc);
    top: -44px;
    right: 50%;
    width: 116px;
    z-index: 1;
}