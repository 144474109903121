@font-face {
    font-family: Lato;
    src: url("../assets/fonts/Lato-Regular.ttf") format('truetype'); 
}
@font-face {
    font-family: Poppins;
    src: url("../assets/fonts/Poppins-Regular.ttf") format('truetype'); 
}
@font-face {
    font-family: Roboto;
    src: url("../assets/fonts/Roboto-Regular.ttf") format('truetype'); 
}

@import './config/variables';
@import './config/custom_bootstrap';
@import './extras/textos';
@import './extras/icons';
@import './extras/logos';

@import './components/textField';
@import './components/selectField';
@import './components/inputFileDocu';
@import './components/readMore';
@import './components/listas';
@import './components/tables';
@import './components/menuChip';
@import './components/cardTabla';
@import './components/datePicker';
@import './components/circleStep';
@import './components/radioBox';
@import './components/barProgressStep';
@import './components/progressBarMedioCirculo';
@import './components/barraPerfil';
@import './components/modalError';
@import './components/footer';
@import './components/login';
@import './components/BarsProgress';
@import './components/elementoAccordion';
@import './components/Loading';
@import './components/global';
@import './components/cardInsurane'