.accordion-empresas {
    .accordion-item {
        padding: 12px 32px;
        border: 1px solid $light;
        border-radius: 24px;
    
        .datos-principales {
            color: $textoCampo;
            display: flex;
            align-items: center;
            font-style: normal;
            font-family: 'Poppins', Arial, Helvetica, sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
    
            .dato-monto {
                font-size: 24px;
                line-height: 28px;
                width: 100%;
                text-align: right;
            }
            .is-invalid {
                .form-check-input {
                    border: 1px solid $danger;
                }
            }
        }
    }
    
    .accordion-header {
        border: 0;
        border-color: transparent;
        .accordion-button {
            color: $primary300;
            font-family: 'Lato', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            padding: 0;
        }
    }
    .accordion-body {
        padding: 21px 0 0 0;

        .tabla-detalles thead {
            border-bottom: 1px solid $primary300;
        }
        .tabla-detalles td {
            border: 0;
            font-family: 'Lato', Arial, Helvetica, sans-serif;
            font-style: normal;
            font-size: 14px;
            line-height: 20px;
            padding: 8px 16px;
        }
        .tabla-detalles td:nth-child(2),
        .tabla-detalles th:nth-child(2)  {
            text-align: right;
        }
        .tabla-detalles th:nth-child(1) {
            width: 70%;
            font-weight: 700;
            padding: 16px;
        }
        .tabla-detalles th:nth-child(2) {
            width: 30%;
            font-weight: 700;
            padding: 16px;
        }
        .tabla-detalles tbody tr:nth-child(odd) {
            background-color: $colorCeldasTablas;
        }
    }
}

.accordio-plan-pago {
    .accordion-item {
        background-color: $light;
        border-radius: 8px;
        border: 1px solid $primary300;
        //padding-bottom: 24px;
        margin-bottom: 24px;
        .accordion-header {
            .accordion-button {
                background: transparent;
                /*border-top: 1px solid $primary300;
                border-left: 1px solid $primary300;
                border-right: 1px solid $primary300;
                border-bottom: 1px solid rgb(171, 107, 241, 0);
                
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;*/
                &.collapsed {
                    /*border-radius: 16px;
                    border-bottom: 1px solid rgb(171, 107, 241, 1);*/

                    //transition: border-color 1s linear;
                }
            }
        }
        .accordion-collapse {
            //background-color: $light;
            background: transparent;
            /*border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
            border-left: 1px solid $primary300;
            border-right: 1px solid $primary300;
            border-bottom: 1px solid $primary300;*/
            .accordion-body {
                .texto-subtitulo {
                    color: $primary300;
                    text-align: center;
                }
                .texto-enganche {
                    color: $Surface400;
                    font-family: 'Lato', Arial, Helvetica, sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                }
                .texto-error {
                    font-family: 'Lato', Arial, Helvetica, sans-serif;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 16px;
                }
                .col-plazos {
                    align-items: center;
                    background-color: rgba(165, 165, 165, 0.2);
                    flex-direction: row;
                    justify-content: space-between;
                    .monto {
                        color: $Surface500;
                        margin: 0;
                    }
                    &.col-plazo-enable {
                        .monto {
                            color: $Surface400;
                        }
                        .plazo {
                            color: $Surface400;
                        }
                    }
                    
                }
            }
        }
    }
}