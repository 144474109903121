.carga-documento {
    background: $light;
    cursor: pointer;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-size: 16px;
    padding: 16px;
    width: 100%;

    .input-file {
        display: none;
    }
    .texto-default {
        color: $Surface500;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 20px;
        i {
            margin-bottom: 16px;
        }
        b {
            font-weight: 700;
            text-align: center;
        }
        .texto-formatos {
            font-size: 14px;
            margin-bottom: 16px;
        }
        .nav-link {
            color: $primary300;
            font-weight: 700;
            line-height: 19px;
            text-decoration: underline;
        }
    }
    .datos-image {
        display: flex;
        div {
            margin-left: 20px;
            width: 82%;
            .icon-picture {
                margin-top: 8px;
            }
            .name-image {
                font-weight: 700;
            }
            .size-image {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin: 0;
            }
            .nav-link {
                color: $primary300;
                font-weight: 700;
                line-height: 19px;
                text-decoration: underline;
            }
        }
        
    }
}

.carga-documento-icon {
    @extend .carga-documento;
    display: flex;
    border-radius: 8px;
    min-height: 119px;
    &.active {
        border: 1px solid #00C767;
    }
    .nombre-real {
        color: #A5A5A5;
        font-weight: 400 !important;
    }
    .size-image {
        color: #A5A5A5;
    }
}