.icon-add {
    background: url(../../assets/images/icons/add.png) 0 0 no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
}
.icon-solicitud {
    background: url(../../assets/images/icons/solicitudes.png) 0 0 no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
}
.icon-trophy {
    background: url(../../assets/images/icons/trophy.png) 0 0 no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
}
.icon-arrow-left {
    background: url(../../assets/images/icons/arrow-left.png) 0 0 no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
}
.icon-paloma {
    background: url(../../assets/images/icons/paloma.png) 0 0 no-repeat;
    display: inline-block;
    width: 22px;
    height: 17px;
}
.icon-calendario {
    background: url(../../assets/images/icons/calendario.png) 0 0 no-repeat;
    display: inline-block;
    width: 18px;
    height: 20px;
}
.icon-user {
    background: url(../../assets/images/icons/user.png) 0 0 no-repeat;
    display: inline-block;
    width: 16px;
    height: 22px;
}
.icon-user-duo {
    background: url(../../assets/images/icons/user-duo.png) 0 0 no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
}
.icon-user-triple {
    background: url(../../assets/images/icons/user-triple.png) 0 0 no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
}
.icon-pin {
    background: url(../../assets/images/icons/pin.png) 0 0 no-repeat;
    display: inline-block;
    width: 18px;
    height: 22px;
}
.icon-phone {
    background: url(../../assets/images/icons/phone.png) 0 0 no-repeat;
    display: inline-block;
    width: 20px;
    height: 20px;
}
.icon-chat {
    background: url(../../assets/images/icons/chat.png) 0 0 no-repeat;
    display: inline-block;
    width: 28px;
    height: 26px;
}
.icon-eye-off {
    background: url(../../assets/images/icons/eye-off.svg) 0 0 no-repeat;
    display: inline-block;
    width: 28px;
    height: 26px;
}
.icon-pin-sucursal {
    background: url(../../assets/images/icons/pin-sucursal.svg) 0 0 no-repeat;
    display: inline-block;
    width: 16px;
    height: 24px;
}
.icon-share {
    background: url(../../assets/images/icons/share.png) 0 0 no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
}
.icon-identificacion {
    background: url(../../assets/images/icons/identificacion.png) 0 0 no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
}
.icon-comprobante-domicilio {
    background: url(../../assets/images/icons/comprobante-domicilio.png) 0 0 no-repeat;
    display: inline-block;
    width: 15px;
    height: 21px;
}
.icon-comprobante-ingresos {
    background: url(../../assets/images/icons/comprobante-ingresos.png) 0 0 no-repeat;
    display: inline-block;
    width: 17px;
    height: 21px;
}
.icon-contactos {
    background: url(../../assets/images/icons/contactos.png) 0 0 no-repeat;
    display: inline-block;
    width: 23px;
    height: 21px;
}
.icon-documentos {
    background: url(../../assets/images/icons/documento.png) -4px -1px no-repeat;
    display: inline-block;
    width: 16px;
    height: 22px;
}
.icon-maletin {
    background: url(../../assets/images/icons/maletin.png) 0 0 no-repeat;
    display: inline-block;
    width: 22px;
    height: 18px;
}
.icon-historial {
    background: url(../../assets/images/icons/historial.png) 0 0 no-repeat;
    display: inline-block;
    width: 20px;
    height: 28px;
}
.icon-documentacion {
    background: url(../../assets/images/icons/documentacion.png) 0 0 no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
}
.icon-zoom-area {
    background: url(../../assets/images/icons/zoom-area.png) 0 0 no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
}
.icon-whatsapp {
    background: url(../../assets/images/icons/whatsapp.png) 0 0 no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
}
.icon-messenge {
    background: url(../../assets/images/icons/message.png) 0 0 no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
}
.icon-upload {
    background: url(../../assets/images/icons/upload.png) 0 0 no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
}
.icon-picture {
    background: url(../../assets/images/icons/picture.png) 0 0 no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
}
.icon-notificacion {
    background: url(../../assets/images/icons/notificacion.png) 0 0 no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
}
.icon-vomito {
    background: url(../../assets/images/icons/icon-vomito.png) 0 0 no-repeat;
    display: inline-block;
    width: 17px;
    height: 17px;
}
.icon-error {
    background: url(../../assets/images/icons/error.png) 0 0 no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
}
.icon-success {
    background: url(../../assets/images/icons/checkmark.png) 0 0 no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
}
.icon-info {
    background: url(../../assets/images/icons/information.png) 0 0 no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
}
.icon-warning {
    background: url(../../assets/images/icons/warning.png) 0 0 no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
}

.icon-info-gris {
    background: url(../../assets/images/icons/information_gris.png) 0 0 no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
}

.icon-seguros {
    background: url(../../assets/images/icons/security.svg) -3px 0 no-repeat;
    display: inline-block;
    width: 25px;
    height: 31px;
}

.icon-checkmark {
    background: url(../../assets/images/icons/checkmark.svg) -3px 0 no-repeat;
    display: inline-block;
    width: 40px;
    height: 40px;
}

.icon-visto-recientemente {
    background: url(../../assets/images/icons/recently-viewed.svg) -3px 0 no-repeat;
    display: inline-block;
    width: 40px;
    height: 40px;
}

.icon-rejected {
    background: url(../../assets/images/icons/rejected.svg) -3px 0 no-repeat;
    display: inline-block;
    width: 40px;
    height: 40px;
}
.icon-trophy-white {
    background: url(../../assets/images/icons/trophy_white.png) 0 0 no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
}
.icon-button-select {
    background: url(../../assets/images/icons/radio-button-select.svg) 0 0 no-repeat;
    display: inline-block;
    width: 25px;
    height: 24px;
}
.icon-documento-morado {
    background: url(../../assets/images/icons/document-morado.svg) 0 0 no-repeat;
    display: inline-block;
    width: 25px;
    height: 24px;
}

.icon-alerta-success {
    background: url(../../assets/images/icons/icon-success.png) 0 0 no-repeat;
    display: inline-block;
    width: 70px;
    height: 70px;
    background-size: cover;
}

.icon-trophy-color {
    background: url(../../assets/images/icons/trophy-color.png) 0 0 no-repeat;
    display: inline-block;
    width: 70px;
    height: 70px;
    background-size: cover;
}
