.seccion-login {
    background: url(.././../assets/images/backgrounds/background-login.png) 0 0 no-repeat;
    background-size: cover;
    min-height: 100vh;
    .container {
        .col-informacion-pagina {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 36px 0 131px 60px;
            .logo-atria {
                width: 116px;
            }
            .texto-titulo {
                color: $light;
                font-weight: 700;
                font-size: 48px;
                line-height: 56px;
            }
        }

        .col-login {
            min-height: 715px;
            .card-login {
                background: rgba(255, 255, 255, 0.1);
                backdrop-filter: blur(26px);
                border: 0;
                border-radius: 24px;
                color: $light;
                width: 472px;
        
                .card-body {
                    padding: 48px 52px;
                    .card-title {
                        font-family: 'Poppins', Arial, Helvetica, sans-serif;
                        font-weight: 600;
                        font-size: 32px;
                        line-height: 36px;
                        margin: 0 0 16px 0;
                    }
                    .card-text {
                        font-family: 'Lato', Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        margin: 0 0 32px 0;
                    }
                    .form-label {
                        color: $light;
                        padding-left: 16px;
                    }
                    .form-check-label {
                        color: $primary100;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    .text-forgot-password {
                        color: $primary100;
                        cursor: pointer;
                        display: inline-block;
                        font-family: 'Lato', Arial, Helvetica, sans-serif;
                        font-weight: 900;
                        font-size: 10px;
                        line-height: 18px;
                        letter-spacing: 4%;
                        margin: 0;
                    }
                    .btn-primary-300 {
                        height: 52px;
                    }
                }
            }
        }
        .card-olvido {
            @extend .card-login;
            
            .card-body {
                .text-olvido.card-text {
                    font-size: 14px;
                    text-align: center;
                }
                .text-olvido.card-text .text-numero {
                    color: #F0D3FF;
                }
            }
            
        }
    }
}

