.barra-perfil {
    color: $Surface500;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    padding-top: 16px;

    .perfil {
        font-size: 24px;
        line-height: 28px;
        margin: 0;
    }

    .fecha-actual {
        font-family: 'Lato', Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
    }

    .numero-notificaciones {
        font-size: 16px;
        line-height: 20px;
        margin-right: 8px;
    }

    .foto-perfil {
        cursor: pointer;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-left: 32px;
        
    }
}