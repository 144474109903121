.card-insurance {
    background: linear-gradient(180deg, rgba(171, 107, 241, 0) 38.34%, rgba(240, 211, 255, 0.8) 151.23%), #FFFFFF;
    border: 1px solid #F0D3FF;
    border-radius: 16px;
    color: $Surface500;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: 208px;
    padding: 23px 32px 23px 32px;
    .texto-numero {
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        font-style: 'normal';
        font-weight: 400;
        font-size: 48px;
        line-height: 72px;

    }

    &.aprobada {    
        background: linear-gradient(180deg, rgba(201, 252, 207, 0) 38.34%, rgba(201, 252, 207, 0.8) 151.23%), #FFFFFF;
        border: 1px solid #C9FCCF;
    }
    &.rechazadas {
        background: linear-gradient(180deg, rgba(254, 225, 212, 0) 38.34%, rgba(254, 225, 212, 0.8) 151.23%), #FFFFFF;
        border: 1px solid #FEE1D4;
    }
}