.lista-documentos {
    color: #FFFFFF;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    list-style: none;

    li {
        display: flex;
        align-items: center;
        text-align: left;
        padding-bottom: 16px;
        p{
            width: 90%;
        }
        i {
            width: 10%;
            margin-right: 8px;
        }
    }
}

.lista-bullets {
    flex-direction: column;
    list-style: none;
    padding: 0;
}
.lista-bullets li {
    display: flex;
    padding: 6px 0;
}
.lista-bullets li::before {
    background: url(../../assets/images/icons/checkmark_black.svg) 0 0 no-repeat;
    content: '';
    display: inline-block;
    margin-right: 8px;
    padding-left: 10px;
    width: 24px;
    height: 24px;
}