.texto-informacion {
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}
.texto-informacion-roboto {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}
.texto-informacion-pequenio {
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
}

.texto-error {
    color: $danger;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    margin: 0;
}
.texto-monto-min {
    @extend .texto-error;
    color: $success;
}
.texto-titulo {
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
}
.texto-subtitulo {
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}

.texto-liga {
    color: $primary400;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}